/**
 * Contains application utility methods that help with forming the business logic/feature of the
 * application, but are shared among differnt modules.
 */
import i18n from '@toolkit/util/i18n';
declare const __OP_IMG__;
declare const __OP_IMG_DET__;
// Utils
import {
  isNotNilOrEmpty,
  verifyProperty,
  flatten,
  head,
  map,
  last,
  findIndexById,
  sortBy,
  prop,
  lte,
  filter,
  pipe,
  isNilOrEmpty,
  intersection,
  merge,
  isNil,
  equals,
  T,
  toUpper,
  formatCents,
} from '@src/shared/src/util/general';
import {
  getMainLegs,
  getMainLegsHeadTS,
} from '@src/shared/src/util/trips';
import { titleize } from './general';
// Constants
import {
  ERRORS,
  NOTIF_TYPE,
  STATUS,
  TRANSIT_TYPES,
  VEHICLE_TYPES,
} from '@src/shared/src/const/app';
import {
  TRANSPORT_ICONS,
  CHECKOUT_STEPS,
  ROUTES,
} from '@toolkit/const/app';
const iziToast = require('izitoast');
import { API_URL } from '@src/shared/src/const/api';
// Actions
// Models
import {
  CoordinatesModel,
  HotelModel,
  LegModel,
  OptionModel,
  SearchNodeModel,
  TransportSegmentModel,
  TripModel,
  PolicyViolationModel,
} from '@src/shared/src/models';
// Interfaces
// Components
// Styles
require('izitoast/dist/css/iziToast.css');

// --General--

const googleAutocompleteService = new google.maps.places.AutocompleteService();
const googlePlacesService = new google.maps.places.PlacesService(
  document.createElement('div')
);
export const hashHistory = require('history').createHashHistory();
export const getRouteParam = (match:any, paramKey:string) =>
  verifyProperty(null, match, ['params', paramKey]);

// --Search--

// Taken from https://developers.google.com/maps/documentation/geocoding/intro
const googleSuggestionFilter:string[] = [
  'country',
  'administrative_area_level_1',
  'administrative_area_level_2',
];

/**
 * Returns an array of possible places from Google API given a search term, returns an empty array
 * if nothing is found
 *
 */

export const getGoogleSuggestions = (searchTerm: string) => {
  return new Promise((resolve, reject) => {
    googleAutocompleteService.getPlacePredictions(
      {
        input: searchTerm,
        locationBias: new google.maps.Circle({
          center: { lat: 51.3127, lng: 9.4797 },
          radius: 700000,
        }),
      },
      (predictions: any, status: any) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        if (isNotNilOrEmpty(predictions)) {
          resolve(
            predictions
              .filter((pr) => isNilOrEmpty(intersection(pr.types, googleSuggestionFilter)))
              .map((pr: any) => (
                { id: pr.id, name: pr.description, reference: pr.reference, coordinates: 0, types: pr.types }
              ))
          );
        }
      } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        resolve([]);
      } else {
        // logger(`Inavlid status from getGoogleSuggestions():${status}`, LOG_TYPE.ERROR);
        reject(status);
      }
    });
  });
};

export const getGoogleSuggestionsForSelect = (searchTerm:string, defaultOption?:OptionModel) => {
  if (isNotNilOrEmpty(searchTerm)) {
    return getGoogleSuggestions(searchTerm).then((response:any) => {
      return { options: response.map((res) => ({ label: res.name, value: res.id }))};
    });
  } else {
    return Promise.resolve({options: [] });
  }
};

export const getGoogleSuggestionDetail = (placeRef: string):Promise<CoordinatesModel> => {
  return new Promise((resolve, reject) => {
    googlePlacesService.getDetails({placeId: placeRef}, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        resolve(
          new CoordinatesModel(place.geometry.location.lat(), place.geometry.location.lng())
        );
      } else {
        reject(`Inavlid status from getGoogleSuggestionDetail():${status}`);
      }
    });
  });
};

export const parseOptionToSearchNodeModel = (opt:OptionModel) => {
  const node = new SearchNodeModel();
  node.id = opt.value;
  node.name = opt.label;
  node.reference = opt.value;
  return node;
};


// --Navigation--

export const navigate = (routePath:string) => {
  hashHistory.push(routePath);
};

export const navigateExternally = (routePath:string) => {
    window.location.replace(routePath);
};

export const isCurrentPath = (route:string) => hashHistory.location.pathname.includes(route);

export const checkoutNavigationCheck = (basketStatus:STATUS, routeParam:CHECKOUT_STEPS, searchId:number) => {
  switch (basketStatus) {
    case STATUS.PREPARE_FAILED:
    case STATUS.PREPARE_FINISHED:
      if (routeParam !== CHECKOUT_STEPS.PREPARE && routeParam !== CHECKOUT_STEPS.PAYMENT) {
        navigate(`${ROUTES.BOOKING.PREPARE}${searchId}`);
      }
      break;
    case STATUS.CONFIRM_FAILED:
    case STATUS.CONFIRM_PENDING:
    case STATUS.CONFIRM_FINISHED:
      if (routeParam !== CHECKOUT_STEPS.CONFIRM) {
        navigate(`${ROUTES.BOOKING.CONFIRM}${searchId}`);
      }
      break;
  }
};

// --Trips--

/**
 * Returns a class name for an icon based on the supplied vehicle type
 *
 * @param {VEHICLE_TYPES} vehicleType - The vehicle type
 * @returns {string} The class name for the icon of the specified vehicle type
 */
export const getVehicleIcon = (vehicleType:VEHICLE_TYPES = VEHICLE_TYPES.FOOT): string => {
  switch (vehicleType.toUpperCase()) {
    case VEHICLE_TYPES.BUS:
      return TRANSPORT_ICONS.BUS;
    case VEHICLE_TYPES.PLANE:
      return TRANSPORT_ICONS.PLANE;
    case VEHICLE_TYPES.TRAIN:
      return TRANSPORT_ICONS.TRAIN;
    case VEHICLE_TYPES.PUBLIC_TRANSIT:
      return TRANSPORT_ICONS.PUBLIC_TRANSPORT;
    case VEHICLE_TYPES.TAXI:
      return TRANSPORT_ICONS.TAXI;
    case VEHICLE_TYPES.WALKING:
    case VEHICLE_TYPES.FOOT:
      return TRANSPORT_ICONS.WALKING;
    default:
      return TRANSPORT_ICONS.PUBLIC_TRANSPORT;
  }
};

export const getShuttleIcon = (value:string) => {
  switch(value) {
    case TRANSIT_TYPES.PUBLIC_TRANSIT:
      return TRANSPORT_ICONS.PUBLIC_TRANSPORT;
    case TRANSIT_TYPES.TAXI:
      return TRANSPORT_ICONS.TAXI;
    case TRANSIT_TYPES.NONE:
      return TRANSPORT_ICONS.NONE;
    default:
      return TRANSPORT_ICONS.PUBLIC_TRANSPORT;
  }
};


export const requireHotelImages = () => {
  const images: any = require.context('../../assets/img/hotels', true, /\.png$/);
  return images.keys().map(images);
}

export const requireAsset = (fileName:string, type:string = 'image') => {
  if (type === 'image') {
    return require(`../../assets/img/${fileName}`);
  }
};

export const getTripOperatorImageURL = (trip:TripModel) => {
  const mainLegs = getMainLegs(trip.legs);
  const mainLegsSuppliers = head(getMainLegsHeadTS(mainLegs).suppliers).split('|');
  const mainLegsVehicle = head(getMainLegsHeadTS(mainLegs).vehicles);
  return getOperatorImageURL(head(mainLegsSuppliers), mainLegsVehicle);
};

export const getOperatorImageURL = (operatorCode: string, vehicleType: string) =>
  `/assets/operator_images/${vehicleType.toLowerCase()}/${operatorCode}.png?v=${__OP_IMG__[operatorCode]}`;

export const getOperatorImageDetailURL = (operatorCode: string, vehicleType: string) =>
  `/assets/operator_images_detail/${vehicleType.toLowerCase()}/${operatorCode}.png?v=${__OP_IMG_DET__[operatorCode]}`;

export const sortByWidth = sortBy(prop('width'));

/**
 * Generates a UI specific routes, used for displaying stops route on leg component
 */
export const generateTripRoutes = (tripLegs:LegModel[], tripDuration:number) => {
  const filteredTS = flatten(map((leg:LegModel) => {
    if (leg.legOption.changeable) {
      const tsVehicle = head(head(leg.legOption.transportSegments).vehicles);
      const vehicleForIcon = tsVehicle === VEHICLE_TYPES.WALKING ? VEHICLE_TYPES.PUBLIC_TRANSIT : tsVehicle;
      const sumOfTSDuration = leg.legOption.duration;
      const tmpWidth =  Math.floor((sumOfTSDuration/ tripDuration) * 100);
      return [{
        id: leg.legOption.transportSegments[0].id,
        key: `tr-id-${leg.legOption.transportSegments[0].id}`,
        icon: getVehicleIcon(vehicleForIcon),
        duration: sumOfTSDuration,
        width: tmpWidth < 10 ? 10 : tmpWidth,
        class: leg.legOption.changeable ? 'tcp-trip-routes-item-public' : '',
        style: { width: `${tmpWidth < 10 ? 10 : tmpWidth}%` },
      }];
    } else {
      return map((ts:TransportSegmentModel) => {
        const tmpWidth =  Math.floor((ts.duration / tripDuration) * 100);
        return {
          id: ts.id,
          key: `${ts.id}-`,
          icon: getVehicleIcon(head(ts.vehicles)),
          duration: ts.duration,
          width: tmpWidth < 10 ? 10 : tmpWidth,
          class: leg.legOption.changeable ? 'tcp-trip-routes-item-public' : '',
          style: { width: `${tmpWidth < 10 ? 10 : tmpWidth}%` },
        };
      }, filter((ts) => toUpper(head(ts.vehicles)) !== VEHICLE_TYPES.FOOT, leg.legOption.transportSegments));
    }
  }, tripLegs));
  const sumOfWidth = filteredTS.reduce((accu, next) => accu + next.width, 0);
  const sTS = sortByWidth(filteredTS);
  filteredTS[findIndexById(last(sTS).id)(filteredTS)].width =
    last(sTS).style.width = last(sTS).width - (sumOfWidth - 100) + '%';

  return filteredTS;
};

// --Hotels--

// Filters hotels array based on rating
const getHotelsByRating = (rating:number) =>
  filter(pipe(prop('userRating'), lte(rating)));

// Recursively downgrade rating until getting results
export const getHotelsResultsByRating = (hotels:HotelModel[], rating:number) => {
  const result = getHotelsByRating(rating)(hotels);
  if (isNilOrEmpty(result) && rating > 0) {
    return getHotelsResultsByRating(hotels, rating - 0.5);
  }
  return result;
};

// --Notification--

export const notify = (
  msg:string,
  type:NOTIF_TYPE = NOTIF_TYPE.WARNING,
  buttons:[string, (instance?:any, toast?:any) => void][] = [],
  displayMode = 2) => {

  const notifySettings:any = {
    message: msg,
    position: 'topRight',
    buttons,
    displayMode,
  };

  switch (type) {
    case NOTIF_TYPE.ERROR:
      iziToast.error(merge(notifySettings, { timeout: false }));
      break;
    case NOTIF_TYPE.SUCCESS:
      iziToast.success(notifySettings);
      break;
    case NOTIF_TYPE.WARNING:
      iziToast.warning(merge(notifySettings, { timeout: false}));
      break;
    default:
      iziToast.info(notifySettings);
  }
};

export const notifyError = (errorMsg:ERRORS | string[], notificationMsg?:string) => {
  if (Array.isArray(errorMsg)) {
    errorMsg.map((err) => notify(i18n.t(err), NOTIF_TYPE.ERROR, [], 0));
  } else {
    notify(isNil(notificationMsg) ? i18n.t(errorMsg) : notificationMsg, NOTIF_TYPE.ERROR);
  }
}

export const notifyTranslatedError = (errors: string[]) => {
  errors.map((err) => notify(err, NOTIF_TYPE.ERROR, [], 0));
}

// --Errors--
export const timeoutErrorCond = [
  equals(ERRORS.TIMEOUT),
  () => {
    if (hashHistory.location.pathName !== '/') {
      notify(
        i18n.t('global.somethingWrong'),
        NOTIF_TYPE.WARNING,
        [
          [`<button>${i18n.t('notifyError.button.backToSearch')}</button>`, () => navigate(ROUTES.HOME)],
          [`<button>${i18n.t('notifyError.button.reload')}</button>`, () => location.reload()],
        ]
      );
    }
  },
];

export const defaultErrorCond = [T, (err) => notifyError(err)];

export const translatedErrorCond = [T, (err) => notifyTranslatedError(err)];

export const baseErrorCond = [timeoutErrorCond, defaultErrorCond];

export const highlightKeyword = (originalStr:string, keyword:string) =>
  originalStr.replace(
    new RegExp(keyword.replace(/[^a-zA-Z0-9]/g, '\\$&'), 'ig'), // include \ before each special characters
    (match) => `<b>${match}</b>` // make original text bold
  );

const buildTransportViolationMessage = (
  vehicle:string,
  travelClass:string,
  travelLength:string,
  price:number,
  policyPrice:number,
) => {

  const vehicleLabel = `${i18n.t(`global.${vehicle}`)}:`;
  const travelClassLabel = i18n.t(`global.${vehicle}.travelClassLabel`);
  const travelClassValue = i18n.t(`global.${vehicle}.travelClass.${travelClass}`);
  const travelLengthMessage = travelLength ?
    `${i18n.t(`global.travelLengthLabel`)} ${i18n.t(`global.travelLength.${travelLength}`)}` : '';

  let priceMessage = '';
  if (!isNil(price)) {
    if (policyPrice > 0) {
      priceMessage = i18n.t('policyViolations.message.exceedPrice', {
        price: formatCents(price),
        policyPrice: formatCents(policyPrice),
      });
    } else {
      priceMessage = i18n.t('policyViolations.message.notAllowed');
    }
  }

  return `${vehicleLabel} ${travelClassLabel} ${travelClassValue} ${travelLengthMessage} ${priceMessage}`;
};

const buildHotelViolationMessage = (
  regionContinent:string,
  regionCountry:string,
  regionName:string,
  price:number,
  policyPrice:number,
  stars:number,
  policyStars:number,
  maxPriceWithBreakfast:number
  ) => {
    const vehicleLabel = `${i18n.t('global.hotel')}:`;
    let regionLocation = '';
    let priceMessage = '';
    let starsMessage = '';
    let breakfastInclMessage = '';

    if (regionName) {
      regionLocation = regionName === 'anywhere' ? i18n.t('global.anywhere') : regionName;
    } else if (regionCountry) {
      regionLocation = regionCountry;
    } else if (regionContinent) {
      regionLocation = regionContinent;
    }

    if (maxPriceWithBreakfast) {
      breakfastInclMessage = i18n.t('policyViolations.message.withBreakfast');
    }

    if (!isNil(price)) {
      if (price > 0) {
        priceMessage = i18n.t('policyViolations.message.exceedHotelPricePerNight', {
          price: formatCents(price),
          policyPrice: formatCents(policyPrice),
        });

        if (maxPriceWithBreakfast) {
          priceMessage = `${priceMessage} ${i18n.t(
            'policyViolations.message.exceedHotelPricePerNightWithBreakfast',
            { maxPriceWithBreakfast: formatCents(maxPriceWithBreakfast) },
          )}`;
        }
      } else {
        priceMessage = i18n.t('policyViolations.message.notAllowed');
      }
    }

    if (!isNil(stars) && !isNil(policyStars) && (stars > policyStars)) {
      starsMessage = `${!isNil(price) ? '&' : ''} ${i18n.t('policyViolations.message.exceedHotelStars',
        { stars: String(stars), policyStars: String(policyStars) })}`;
    }

    return `
      ${vehicleLabel}
      ${regionLocation}
      ${breakfastInclMessage}
      ${priceMessage}
      ${starsMessage}`;
  };

export const getPolicyViolationMessage = (policyViolation:PolicyViolationModel) => {
  switch (policyViolation.policyTypeName) {
    case 'transport':
      return buildTransportViolationMessage(
        policyViolation.vehicle,
        policyViolation.transportData.travelClass,
        policyViolation.transportData.travelLength,
        policyViolation.transportData.price,
        policyViolation.transportData.policyPrice
      );

    case 'hotel':
      return buildHotelViolationMessage(
      policyViolation.hotelData.regionContinent,
      policyViolation.hotelData.regionCountry,
      policyViolation.hotelData.regionName,
        policyViolation.hotelData.price,
        policyViolation.hotelData.policyPrice,
        policyViolation.hotelData.stars,
        policyViolation.hotelData.policyStars,
        policyViolation.policy.maxPriceWithBreakfast
      );

      case 'rental':
        return i18n.t('policyViolations.message.exceededRental', {
        policyCarCategory: titleize(policyViolation.rentalData.policyCarCategory),
        policyPrice: formatCents(policyViolation.rentalData.policyPrice),
      });
  }
};

export const redirectToBackendForLogin = () => {
  window.location.replace(`${API_URL.DEFAULT}/sessions/new`);
}